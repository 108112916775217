import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  handleCollabQrScan,
  handleCustomerQrScan,
} from '../../actions/userActions';
import { CustomTextField } from '../../components/Utils/UIUtils';

const SCAN_TYPES = {
  CUSTOMER: 'customer',
  COLLAB: 'collab',
};

const QRCodeScanner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleQRScan = useSelector((state) => state.handleQRScan);
  const inputRef = useRef(null);
  const [scannedData, setScannedData] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [scanTimeout, setScanTimeout] = useState(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (scannedData && !isScanning) {
      if (scanTimeout) {
        clearTimeout(scanTimeout);
      }

      const timeout = setTimeout(() => {
        setIsScanning(true);
        processScannedData(scannedData);
        setScannedData('');
        setIsScanning(false);
      }, 1000);

      setScanTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedData]);

  const processScannedData = (data) => {
    const [type, id] = data.split("'");
    if (type === SCAN_TYPES.COLLAB) {
      dispatch(handleCollabQrScan(id));
    } else if (type === SCAN_TYPES.CUSTOMER) {
      dispatch(handleCustomerQrScan(id));
    } else {
      setScannedData('');
    }
  };

  const handleInputChange = (event) => {
    setScannedData(event.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography
        variant="h1"
        sx={{
          color: 'primary.main',
          fontWeight: '400',
          fontSize: '48px',
        }}
      >
        {t('QR_SCANNER.TITLE')}
      </Typography>

      <Box sx={{ width: { xs: '100%', md: '50%' }, px: 2 }}>
        <CustomTextField
          ref={inputRef}
          type="text"
          sx={{
            '& input': {
              height: 50,
            },
          }}
          onChange={handleInputChange}
          value={scannedData}
          autoFocus
        />
      </Box>

      {handleQRScan.loading ? (
        <Typography variant="body1">{t('QR_SCANNER.LOADING')}</Typography>
      ) : handleQRScan.error ? (
        <Typography variant="body1">{handleQRScan.error}</Typography>
      ) : handleQRScan.success ? (
        <Typography
          variant="body1"
          sx={{ color: 'primary.main', fontSize: 26 }}
        >
          {handleQRScan.customer.lessons === 0
            ? `${handleQRScan.customer.fullName} hai completato tutte le lezioni.`
            : t('QR_SCANNER.SUCCESS', {
                name: handleQRScan.customer.fullName,
              })}
        </Typography>
      ) : null}
    </Box>
  );
};

export default QRCodeScanner;
