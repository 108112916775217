import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Typography,
  alpha,
} from '@mui/material';
import { ErrorOutlineRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import WebsiteLogo from '../../assets/images/fisicamentealbaro-logo.svg';
import { login } from '../../actions/userActions';
import { theme } from '../../theme';
import { StyledButton } from '../../components/Utils/UIUtils';
import { loginSchema } from '../../schemas';
import { CustomInput } from '../../components/Forms/CustomInput';
import { Link } from 'react-router-dom';
import { CustomPasswordInput } from '../../components/Forms/CustomPasswordInput';

export default function Login() {
  const dispatch = useDispatch();
  const { error, success, loading } = useSelector((state) => state.userLogin);
  const { t } = useTranslation();

  const onSubmit = (values, actions) => {
    const formData = {
      email: values.email,
      password: values.password,
    };
    dispatch(login(formData));

    actions.setSubmitting(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Stack
        sx={{
          width: { xs: '100%', sm: '450px' },
          border: { xs: 'none', sm: '1px solid' },
          borderColor: { xs: 'transparent', sm: 'primary.neutral200' },
          borderRadius: theme.shape.defaultBorderRadius,
          p: 4,
        }}
        gap={2}
      >
        <Link href="/" style={{ width: 'fit-content', alignSelf: 'center' }}>
          <img
            src={WebsiteLogo}
            width={120}
            alt="Fisicamente Albaro Logo"
            style={{ display: 'block' }}
          />
        </Link>
        <Stack gap={1} alignItems={'center'} justifyContent={'center'}>
          <Typography
            variant="h1"
            fontSize="22px"
            fontWeight="600"
            color="primary.neutral800"
          >
            {t('LOGIN.TITLE')}
          </Typography>
          <Typography
            variant="body1"
            fontSize="16px"
            color="primary.neutral400"
            sx={{ mb: 1 }}
          >
            {t('LOGIN.SUBTITLE')}
          </Typography>
        </Stack>
        <Stack gap={2}>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={() => loginSchema(t)}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {() => {
              return (
                <Stack gap={2} justifyContent={'center'}>
                  <Form
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                    id="login-form"
                    name="login-form"
                  >
                    <CustomInput
                      label={t('LOGIN.EMAIL')}
                      name="email"
                      id="email"
                      type="email"
                      autoComplete="on"
                      placeholder={t('LOGIN.EMAIL_PLACEHOLDER')}
                      manualMarginBottom={'16px'}
                    />

                    <CustomPasswordInput
                      label={t('LOGIN.PASSWORD')}
                      name="password"
                      id="password"
                      type="password"
                      autoComplete="on"
                      placeholder={t('LOGIN.PASSWORD_PLACEHOLDER')}
                      manualMarginBottom={'16px'}
                    />
                    <Stack gap={2}>
                      <StyledButton
                        variant="contained"
                        type="submit"
                        name="login-button"
                        sx={{
                          borderRadius: theme.shape.defaultBorderRadius,
                          px: 3,
                          mt: 2,
                          whiteSpace: 'nowrap',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: 2,
                          bgcolor: success ? 'success' : 'primary.main',
                          '&:hover': {
                            bgcolor: success ? 'success' : '',
                          },
                        }}
                      >
                        {success
                          ? t('LOGIN.LOGIN_SUCCESS_BUTTON')
                          : t('LOGIN.LOGIN_BUTTON')}
                        {loading && (
                          <CircularProgress color="inherit" size={'16px'} />
                        )}
                      </StyledButton>

                      {error && (
                        <Alert severity={'error'} icon={false}>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={2}
                          >
                            <ErrorOutlineRounded
                              sx={{
                                fontSize: '28px',
                                color: theme.palette.error.main,
                                backgroundColor: alpha(
                                  theme.palette.error.main,
                                  0.1
                                ),
                                borderRadius: '50%',
                                p: 0.5,
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: '16px',
                                textAlign: 'left',
                                color: 'primary.neutral600',
                              }}
                            >
                              {error}
                            </Typography>
                          </Stack>
                        </Alert>
                      )}
                    </Stack>
                  </Form>
                </Stack>
              );
            }}
          </Formik>
        </Stack>
      </Stack>
    </Box>
  );
}
