import { useRef, useState } from 'react';
import {
  Avatar,
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Stack,
  IconButton,
  List,
  ListItem,
  Popover,
  Button,
  Divider,
} from '@mui/material';
import { BiUser, BiMenu } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { logout } from '../../actions/userActions';
import { adminDrawerWidth } from '../../constants/utilsConstants';
import { toggleDrawer } from '../../actions/utilsActions';
import { getTransitionStyle, hasRoles, UserRoles } from '../Utils/UIUtils';
import { capitalizeFullName } from '../Utils/Formating';

const supportedLanguages = [
  { code: 'en-US', label: 'English' },
  { code: 'it-IT', label: 'Italiano' },
];

export function AdminHeader() {
  const theme = useTheme();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const languageBtnDesktop = useRef(null);

  const user = useSelector((state) => state.userLogin);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const [language, setLanguage] = useState(
    currentLanguage || process.env.DEFAULT_WEBSITE_LANGUAGE
  );

  const backgroundTransitionStyle = getTransitionStyle(theme, [
    'background-color',
  ]);
  const textTransitionStyle = getTransitionStyle(theme, ['color']);

  function handleSignOut() {
    dispatch(logout());
  }

  const handleAccount = () => {
    navigate(`/login`);
  };

  const handleDrawer = () => {
    dispatch(toggleDrawer(true));
  };

  const [langPopoverEnchorEl, setLangPopoverEnchorEl] = useState(null);

  const handleLanguageChange = (language) => {
    setLangPopoverEnchorEl(null);
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  const langPopoverOpen = Boolean(langPopoverEnchorEl);

  const handleLangPopoverOpen = (event) => {
    setLangPopoverEnchorEl(event.currentTarget);
  };

  const handleLangPopoverClose = () => {
    setLangPopoverEnchorEl(null);
  };

  const [userPopoverEnchorEl, setUserPopoverEnchorEl] = useState(null);

  const handleUserPopoverOpen = (event) => {
    setUserPopoverEnchorEl(event.currentTarget);
  };

  const handleUserPopoverClose = () => {
    setUserPopoverEnchorEl(null);
  };

  const userPopoverOpen = Boolean(userPopoverEnchorEl);

  const userPopoverId = userPopoverOpen ? 'user-popover' : undefined;
  const langPopoverId = langPopoverOpen ? 'language-popover' : undefined;

  return (
    <Box
      sx={{
        width: { xs: '100%', md: `calc(100% - ${adminDrawerWidth}px)` },
        ml: { xs: 0, md: `${adminDrawerWidth}px` },
        py: 1.5,
        px: 2,
        bgcolor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: 'primary.neutral200',
        height: '72px',
      }}
    >
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <IconButton
          sx={{
            display: { xs: 'inherit', md: 'none' },
            pl: 0,
          }}
          onClick={handleDrawer}
          disableRipple
        >
          <BiMenu
            style={{
              fontSize: '24px',
              color: 'primary.neutral400',
            }}
          />
        </IconButton>

        <Stack>
          <Typography
            variant="h6"
            sx={{
              color: 'primary.neutral800',
              fontWeight: '500',
              fontSize: '18px',
              lineHeight: '24px',
            }}
          >
            <Trans
              i18nKey="MAIN_HEADER.WELCOME_MESSAGE"
              values={{
                name: capitalizeFullName(
                  user?.userAuth?.user?.displayName || 'Admin',
                  t
                ),
              }}
            />
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              color: 'primary.neutral600',
              fontSize: '14px',
              lineHeight: '20px',
            }}
          >
            {hasRoles(user?.userAuth?.user, [UserRoles.SUPER])
              ? t('MAIN_HEADER.SUPER_ADMIN')
              : hasRoles(user?.userAuth?.user, [UserRoles.ADMINISTRATOR])
              ? t('MAIN_HEADER.ADMIN')
              : t('MAIN_HEADER.CUSTOMER')}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <Button
          variant="text"
          aria-describedby={langPopoverId}
          sx={{
            minWidth: 'auto',
            color: 'primary.neutral700',
            fontWeight: '500',
            px: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
            ...textTransitionStyle,
          }}
          aria-haspopup="true"
          aria-owns={langPopoverId}
          onMouseEnter={handleLangPopoverOpen}
          onClick={
            langPopoverOpen ? handleLangPopoverClose : handleLangPopoverOpen
          }
          ref={languageBtnDesktop}
        >
          {language.toUpperCase().slice(0, 2)}
        </Button>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            height: '16px',
            backgroundColor: 'primary.neutral300',
            my: 'auto',
          }}
        />
        {langPopoverEnchorEl && (
          <Popover
            id={langPopoverId}
            open={langPopoverOpen}
            anchorEl={langPopoverEnchorEl}
            onClose={handleLangPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            disableRestoreFocus
            elevation={0}
            sx={{
              pointerEvents: 'none',
              '& .MuiPaper-root': {
                borderRadius: theme.shape.defaultBorderRadius,
                boxShadow: `px 2px 8px rgba(0,0,0,0.1)`,
                bgcolor: 'transparent',
              },
              '& .MuiPopover-paper': {
                borderRadius: theme.shape.defaultBorderRadius,
                boxShadow: `0px 2px 8px rgba(0,0,0,0.1)`,
              },
            }}
          >
            <Stack
              component={'div'}
              onMouseLeave={handleLangPopoverClose}
              sx={{
                p: 0.5,
                pointerEvents: 'auto',
                width: '200px',
                maxWidth: '200px',
                minWidth: '120px',
                backgroundColor: 'text.white',
              }}
            >
              <List disablePadding>
                {supportedLanguages
                  .filter((language) => language.code !== currentLanguage)
                  .map((language, key) => (
                    <ListItem
                      key={key}
                      sx={{
                        p: '8px 12px',
                        borderRadius: theme.shape.defaultBorderRadius,
                        '&:hover': {
                          bgcolor: 'primary.neutral100',
                          cursor: 'pointer',
                        },
                        ...backgroundTransitionStyle,
                      }}
                      onClick={() => handleLanguageChange(language.code)}
                    >
                      <Typography
                        sx={{
                          fontWeight: '400',
                          fontSize: '15px',
                          color: 'primary.neutral700',
                        }}
                      >
                        {language.label}
                      </Typography>
                    </ListItem>
                  ))}
              </List>
            </Stack>
          </Popover>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            '& .MuiBadge-badge': {
              fontSize: '9px',
              minWidth: '16px',
              width: '16px',
              height: '16px',
              lineHeight: '16px',
            },
          }}
        >
          <Popover
            id={userPopoverId}
            open={userPopoverOpen}
            anchorEl={userPopoverEnchorEl}
            onClose={handleUserPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              mt: 1,
              '& .MuiPaper-root': {
                borderRadius: theme.shape.defaultBorderRadius,
                boxShadow: `px 2px 8px rgba(0,0,0,0.1)`,
                bgcolor: 'transparent',
              },
              '& .MuiPopover-paper': {
                borderRadius: theme.shape.defaultBorderRadius,
                boxShadow: `0px 2px 8px rgba(0,0,0,0.1)`,
              },
            }}
          >
            <Stack
              component={'div'}
              sx={{
                p: 0.5,
                pointerEvents: 'auto',
                width: '200px',
                maxWidth: '200px',
                minWidth: '120px',
                backgroundColor: 'text.white',
              }}
            >
              <List disablePadding>
                <ListItem
                  sx={{
                    p: '8px 12px',
                    borderRadius: theme.shape.defaultBorderRadius,
                    '&:hover': {
                      bgcolor: 'primary.neutral100',
                      cursor: 'pointer',
                    },
                    ...backgroundTransitionStyle,
                  }}
                  onClick={handleSignOut}
                >
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '15px',
                      color: 'primary.neutral700',
                    }}
                  >
                    {t('MAIN_HEADER.SIGN_OUT')}
                  </Typography>
                </ListItem>
              </List>
            </Stack>
          </Popover>
          {user && user?.userInfo ? (
            <>
              <Box sx={{ cursor: 'pointer' }}>
                {user?.userAuth?.user?.photoURL ? (
                  <Avatar
                    src={user?.userAuth?.user?.photoURL}
                    sx={{
                      height: '36px',
                      width: '36px',
                    }}
                    onClick={handleUserPopoverOpen}
                  />
                ) : (
                  <Avatar
                    src={user?.userAuth?.user?.photoURL}
                    sx={{
                      height: '36px',
                      width: '36px',
                      backgroundColor: user?.userAuth?.user?.profileColor,
                      fontSize: '16px',
                    }}
                    children={user?.userAuth?.user?.displayName
                      ?.charAt(0)
                      .toUpperCase()}
                    onClick={handleUserPopoverOpen}
                  />
                )}
              </Box>
            </>
          ) : (
            <BiUser
              style={{ cursor: 'pointer' }}
              size={isMobile ? 22 : 24}
              color={theme.palette.text.primary}
              onClick={handleAccount}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
