import * as yup from 'yup';

export const MAX_EMAIL_LENGTH = 100;
export const MAX_MESSAGE_LENGTH = 500;
export const MAX_PHONE_LENGTH = 32;
export const MAX_FULLNAME_LENGTH = 100;
const fullNamePattern =
  /^[a-zA-ZÀ-ÿ\u00C0-\u00FF]+(?: [a-zA-ZÀ-ÿ\u00C0-\u00FF]+)*$/;

export const loginSchema = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(t('VALIDATION.EMAIL_INVALID'))
      .required(t('VALIDATION.EMAIL_REQUIRED'))
      .max(
        MAX_EMAIL_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_EMAIL_LENGTH })
      ),
    password: yup.string().required(t('VALIDATION.PASSWORD_REQUIRED')),
  });
};

export const registerSchema = (t) => {
  return yup.object().shape({
    fullName: yup
      .string()
      .required(t('VALIDATION.FULLNAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      )
      .matches(fullNamePattern, t('VALIDATION.FULLNAME_INVALID')),
    email: yup
      .string()
      .email(t('VALIDATION.EMAIL_INVALID'))
      .required(t('VALIDATION.EMAIL_REQUIRED'))
      .max(
        MAX_EMAIL_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_EMAIL_LENGTH })
      ),
    password: yup.string().required(t('VALIDATION.PASSWORD_REQUIRED')),
    confirmPassword: yup
      .string()
      .required(t('VALIDATION.CONFIRM_PASSWORD_REQUIRED'))
      .oneOf([yup.ref('password'), null], t('VALIDATION.PASSWORD_MISMATCH')),
  });
};

export const addCustomerSchema = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t('VALIDATION.NAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      ),
    surname: yup
      .string()
      .required(t('VALIDATION.SURNAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      ),
    email: yup.string().email(t('VALIDATION.EMAIL_INVALID')).notRequired(),
    dateOfBirth: yup.string().required(t('VALIDATION.DATE_OF_BIRTH_REQUIRED')),
    address: yup.string().required(t('VALIDATION.ADDRESS_REQUIRED')),
    city: yup.string().required(t('VALIDATION.CITY_REQUIRED')),
    taxId: yup.string().required(t('VALIDATION.TAX_ID_REQUIRED')),
    zipcode: yup.string().required(t('VALIDATION.ZIPCODE_REQUIRED')),
    phoneNumber: yup.string().required(t('VALIDATION.PHONE_NUMBER_REQUIRED')),
    courses: yup.array().min(1, t('VALIDATION.COURSES_REQUIRED')),
    paidLessonsCount: yup.number().notRequired(),
    documents: yup.array().notRequired(),
    notes: yup.string().notRequired(),
  });
};

export const updateCustomerSchema = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t('VALIDATION.NAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      ),
    surname: yup
      .string()
      .required(t('VALIDATION.SURNAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      ),
    email: yup.string().email(t('VALIDATION.EMAIL_INVALID')).notRequired(),
    dateOfBirth: yup.string().required(t('VALIDATION.DATE_OF_BIRTH_REQUIRED')),
    address: yup.string().required(t('VALIDATION.ADDRESS_REQUIRED')),
    city: yup.string().required(t('VALIDATION.CITY_REQUIRED')),
    taxId: yup.string().required(t('VALIDATION.TAX_ID_REQUIRED')),
    zipcode: yup.string().required(t('VALIDATION.ZIPCODE_REQUIRED')),
    phoneNumber: yup.string().required(t('VALIDATION.PHONE_NUMBER_REQUIRED')),
    courses: yup.array().min(1, t('VALIDATION.COURSES_REQUIRED')),
    paidLessonsCount: yup.number().notRequired(),
    documents: yup.array().notRequired(),
    notes: yup.string().notRequired(),
  });
};

export const addCollabSchema = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t('VALIDATION.NAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      ),
    surname: yup
      .string()
      .required(t('VALIDATION.SURNAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      ),
    email: yup
      .string()
      .email(t('VALIDATION.EMAIL_INVALID'))
      .required(t('VALIDATION.EMAIL_REQUIRED')),
    dateOfBirth: yup.string().required(t('VALIDATION.DATE_OF_BIRTH_REQUIRED')),
    address: yup.string().required(t('VALIDATION.ADDRESS_REQUIRED')),
    city: yup.string().required(t('VALIDATION.CITY_REQUIRED')),
    taxId: yup.string().required(t('VALIDATION.TAX_ID_REQUIRED')),
    zipcode: yup.string().required(t('VALIDATION.ZIPCODE_REQUIRED')),
    phoneNumber: yup.string().required(t('VALIDATION.PHONE_NUMBER_REQUIRED')),
    courses: yup.array().min(1, t('VALIDATION.COURSES_REQUIRED')),
    customersCount: yup
      .number()
      .required(t('VALIDATION.CUSTOMERS_COUNT_REQUIRED')),
    documents: yup.array().notRequired(),
    notes: yup.string().notRequired(),
  });
};

export const updateCollabSchema = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .required(t('VALIDATION.NAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      ),
    surname: yup
      .string()
      .required(t('VALIDATION.SURNAME_REQUIRED'))
      .max(
        MAX_FULLNAME_LENGTH,
        t('VALIDATION.MAX_LENGTH', { max: MAX_FULLNAME_LENGTH })
      ),
    email: yup
      .string()
      .email(t('VALIDATION.EMAIL_INVALID'))
      .required(t('VALIDATION.EMAIL_REQUIRED')),
    dateOfBirth: yup.string().required(t('VALIDATION.DATE_OF_BIRTH_REQUIRED')),
    address: yup.string().required(t('VALIDATION.ADDRESS_REQUIRED')),
    city: yup.string().required(t('VALIDATION.CITY_REQUIRED')),
    taxId: yup.string().required(t('VALIDATION.TAX_ID_REQUIRED')),
    zipcode: yup.string().required(t('VALIDATION.ZIPCODE_REQUIRED')),
    phoneNumber: yup.string().required(t('VALIDATION.PHONE_NUMBER_REQUIRED')),
    courses: yup.array().min(1, t('VALIDATION.COURSES_REQUIRED')),
    customersCount: yup
      .number()
      .required(t('VALIDATION.CUSTOMERS_COUNT_REQUIRED')),
    documents: yup.array().notRequired(),
    notes: yup.string().notRequired(),
  });
};
