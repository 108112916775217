import { forwardRef, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  Slide,
  Stack,
  Typography,
  alpha,
  lighten,
} from '@mui/material';
import { useSelector } from 'react-redux';

import {
  StyledButton,
  getTransitionStyle,
} from '../../../components/Utils/UIUtils';
import { deleteCollab } from '../../../actions/userActions';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteCollabDialog({
  deleteDialogOpen,
  handleDeleteDialogClose,
  collab,
  dispatch,
  theme,
  t,
}) {
  const buttonTransition = getTransitionStyle(theme, [
    'border-color',
    'background-color',
  ]);

  const deleteCollabSelector = useSelector((state) => state.deleteCollab);

  useEffect(() => {
    if (deleteCollabSelector.success) {
      dispatch({ type: 'CUSTOMER_DELETION_RESET' });
      handleDeleteDialogClose();
    }
  }, [deleteCollabSelector.success, dispatch, handleDeleteDialogClose]);

  const handleDeleteCustomer = () => {
    dispatch(deleteCollab(collab.id, collab.storageId));
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={deleteDialogOpen}
      onClose={handleDeleteDialogClose}
      aria-labelledby="delete-collab-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          width: '500px',
        },
      }}
    >
      <Stack
        variant="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          px: 3,
          py: 2,
        }}
      >
        <Typography
          id="delete-collab-dialog-title"
          variant="h3"
          sx={{
            color: 'primary.neutral800',
            fontSize: '24px',
            fontWeight: '600',
            my: 1,
          }}
        >
          {t('ADMIN.COLLABS.DELETE_COLLAB_MODAL.TITLE')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'primary.neutral600',
            fontSize: '16px',
            fontWeight: '400',
          }}
        >
          {t('ADMIN.COLLABS.DELETE_COLLAB_MODAL.DESCRIPTION')}
        </Typography>
        <DialogActions sx={{ display: 'flex', gap: 1, p: 0 }}>
          <StyledButton
            onClick={handleDeleteDialogClose}
            sx={{
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'primary.neutral200',
              color: 'primary.neutral600',
              height: '40px',
              px: 2,
              ...buttonTransition,
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.neutral200, 0.2),
                borderColor: theme.palette.primary.neutral200,
              },
            }}
          >
            {t('ADMIN.COLLABS.DELETE_COLLAB_MODAL.CANCEL')}
          </StyledButton>
          <StyledButton
            autoFocus
            onClick={handleDeleteCustomer}
            sx={{
              backgroundColor: 'error.main',
              color: 'common.white',
              height: '40px',
              px: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              '&:hover': {
                backgroundColor: lighten(theme.palette.error.main, 0.1),
              },
            }}
          >
            {t('ADMIN.COLLABS.DELETE_COLLAB_MODAL.DELETE')}
          </StyledButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}

export default DeleteCollabDialog;
