import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCneRlsC04pBKhq2z8XCVfDKR5Bg_AKz58',
  authDomain: 'fisicamentealbaro-fb.firebaseapp.com',
  projectId: 'fisicamentealbaro-fb',
  storageBucket: 'fisicamentealbaro-fb.appspot.com',
  messagingSenderId: '256494878388',
  appId: '1:256494878388:web:10e42d932c7f1990f461e1',
  measurementId: 'G-M1E7C5EH8L',
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const auth = getAuth(app);

export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();

export const analytics = getAnalytics(app);
