export function formatTimeAgo(time, t) {
  if (time === null || time === undefined) {
    return t('GENERAL.N/A');
  }

  const currentTime = new Date();
  const messageTime = new Date(time);
  const timeDifference = currentTime - messageTime;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(
    (currentTime.getFullYear() - messageTime.getFullYear()) * 12 +
      currentTime.getMonth() -
      messageTime.getMonth()
  );
  const years = Math.floor(months / 12);

  if (seconds < 60) {
    return t('TIME_FORMAT.JUST_NOW');
  } else if (minutes < 60) {
    return t('TIME_FORMAT.MINUTES_AGO', { minutes });
  } else if (hours < 24) {
    return t('TIME_FORMAT.HOURS_AGO', { hours });
  } else if (days < 7) {
    return t('TIME_FORMAT.DAYS_AGO', { days });
  } else if (weeks < 4) {
    return t('TIME_FORMAT.WEEKS_AGO', { weeks });
  } else if (months < 12) {
    return t('TIME_FORMAT.MONTHS_AGO', { months });
  } else {
    return t('TIME_FORMAT.YEARS_AGO', { years });
  }
}

export function capitalizeFullName(fullname, t) {
  if (fullname === null || fullname === undefined) {
    return t('GENERAL.N/A');
  }

  const words = fullname.split(' ');
  const capitalizedWords = words.map((word) => {
    if (word.length > 3) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(' ');
}

export function capitalizeFirstLetter(string, t) {
  if (string === null || string === undefined) {
    return t('GENERAL.N/A');
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function formatDate(date, t) {
  if (date === null || date === undefined) {
    return t('GENERAL.N/A');
  }

  const formattedDate = new Date(date);
  const day = String(formattedDate.getDate()).padStart(2, '0');
  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const year = formattedDate.getFullYear();

  return `${day}-${month}-${year}`;
}
