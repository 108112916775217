import { useState } from 'react';
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useField } from 'formik';

import { CustomTextField } from '../Utils/UIUtils';

export const CustomPasswordInput = ({
  manualMarginBottom = false,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Stack
      direction="column"
      sx={{
        width: '100%',
        ...(manualMarginBottom && {
          marginBottom: manualMarginBottom,
        }),
      }}
    >
      <Stack gap={1}>
        {label && (
          <Typography
            component={'label'}
            htmlFor={props.id}
            color="primary.neutral800"
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              '& +.MuiInputBase-root': {
                marginTop: 0,
              },
            }}
          >
            {label}
          </Typography>
        )}
        <CustomTextField
          {...field}
          {...props}
          type={showPassword ? 'text' : 'password'}
          sx={{
            '& .MuiInputBase-root fieldset': {
              borderColor: meta.error ? 'error.main' : '',
            },
            '& .MuiFormHelperText-root': {
              ml: 1,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowPassword} disableRipple>
                  {showPassword ? (
                    <VisibilityOutlined sx={{ color: 'primary.neutral500' }} />
                  ) : (
                    <VisibilityOffOutlined
                      sx={{ color: 'primary.neutral500' }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {meta.error ? (
        <Typography sx={{ fontSize: '14px', mt: 0.5, color: 'error.main' }}>
          {meta.error}
        </Typography>
      ) : null}
    </Stack>
  );
};
