import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  alpha,
  Box,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import MUIDataTable from 'mui-datatables';

import { theme } from '../../theme';
import { getCollab } from '../../actions/userActions';
import { Timestamp } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { capitalizeFullName } from '../../components/Utils/Formating';

const CustomIconButton = ({ children, ...props }) => {
  return (
    <IconButton
      sx={{
        borderRadius: theme.shape.defaultBorderRadius,
        border: '1px solid',
        borderColor: 'primary.neutral200',
        width: '32px',
        height: '32px',
        p: 0.8,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.neutral100, 0.5),
        },
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};

function convertCollabToData(collab) {
  return {
    date: (
      <Typography
        sx={{
          color: 'primary.neutral800',
          fontSize: '14px',
        }}
      >
        {collab.date}
      </Typography>
    ),
    count: (
      <Typography
        sx={{
          color: 'primary.neutral800',
          fontSize: '14px',
          fontWeight: '500',
        }}
      >
        {collab.count}
      </Typography>
    ),
  };
}

function convertCollabToSkeleton() {
  return {
    date: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
    count: (
      <Box width={128}>
        <Skeleton variant="text" width={120} height={20} />
      </Box>
    ),
  };
}

const CollabById = () => {
  const { t } = useTranslation();
  const { collab, loading, error } = useSelector((state) => state.collab);
  const [data, setData] = useState([]);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const count = Object.keys(collab?.scans || {}).length;

  useEffect(() => {
    if (collab) {
      const newData = Object.keys(collab.scans)
        .filter((key) => collab.scans[key].hasOwnProperty('count'))
        .sort((a, b) => {
          const dateA = new Date(a.split('-').reverse().join('-'));
          const dateB = new Date(b.split('-').reverse().join('-'));
          return dateB - dateA;
        })
        .slice((page - 1) * pageSize, page * pageSize)
        .map((key) => {
          const date = key;
          const count = collab.scans[key].count;
          return convertCollabToData({ date, count });
        });

      setData(newData);
    }

    if (loading) {
      const skeletonData = Array.from({ length: pageSize }, () =>
        convertCollabToSkeleton()
      );
      setData(skeletonData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collab, page]);

  const columns = [
    {
      name: 'date',
      label: t('ADMIN.COLLAB_BY_ID.TABLE.DATE'),
      options: {
        setCellProps: () => ({ style: { width: '160px' } }),
        filter: false,
        sort: false,
      },
    },
    {
      name: 'count',
      label: t('ADMIN.COLLAB_BY_ID.TABLE.CUSTOMERS'),
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    download: false,
    elevation: 0,
    print: false,
    filter: false,
    search: false,
    selectableRows: 'none',
    viewColumns: false,
    rowsPerPageOptions: [],
    responsive: 'standard',
    caseSensitive: false,
    customFooter: () => null,
    textLabels: {
      body: {
        noMatch: t('ADMIN.COLLAB_BY_ID.TABLE.NO_MATCH'),
      },
    },
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(count / pageSize);
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    if (id) dispatch(getCollab(id));
  }, [dispatch, id]);

  const today = Timestamp.now();
  const dateKey = `${today.toDate().getMonth() + 1}-${today
    .toDate()
    .getFullYear()}`;

  const customerThisMonth = collab?.scans[dateKey]?.count || 0;
  return error ? (
    <Alert severity="error">{error}</Alert>
  ) : (
    <Stack gap={2}>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <CustomIconButton onClick={() => window.history.back()}>
          <ChevronLeftRounded />
        </CustomIconButton>
        <Typography
          variant="h1"
          sx={{
            fontSize: '20px',
            color: 'primary.neutral900',
            fontWeight: '500',
          }}
        >
          <Trans
            i18nKey="ADMIN.COLLAB_BY_ID.TITLE"
            values={{
              name: capitalizeFullName(
                (collab?.name || ' ') + ' ' + (collab?.surname || ' '),
                t
              ),
            }}
          />
        </Typography>
      </Stack>
      <Stack
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <Stack
          sx={{
            borderRadius: theme.shape.defaultBorderRadius,
            bgcolor: 'white',
            border: '1px solid',
            borderColor: 'primary.neutral200',
            p: 2,
            flex: 3,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 3,
          }}
        >
          <Stack gap={1.5} sx={{ width: '100%' }}>
            <Typography
              variant="body1"
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              {t('ADMIN.COLLAB_BY_ID.INFO')}
            </Typography>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{
                width: { xs: '70%', sm: '30%' },
                overflow: 'hidden',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {t('ADMIN.COLLAB_BY_ID.CUSTOMERS_LEFT')}
              </Typography>
              <Chip label={collab?.customersCount || 0} size="medium" />
            </Stack>

            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              sx={{
                width: { xs: '70%', sm: '30%' },
                overflow: 'hidden',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'primary.neutral600',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {t('ADMIN.COLLAB_BY_ID.CUSTOMERS_THIS_MONTH')}
              </Typography>
              <Chip label={customerThisMonth} size="medium" />
            </Stack>
          </Stack>

          <Stack
            sx={{
              width: { xs: '100%', sm: '50%' },
              gap: 1.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: 'primary.main',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              {t('ADMIN.COLLAB_BY_ID.TABLE.TITLE')}
            </Typography>
            <Box
              sx={{
                width: '100%',
                '& .MuiPaper-root': {
                  width: '100%',
                  display: 'table',
                  tableLayout: 'fixed',
                },
              }}
            >
              <MUIDataTable data={data} columns={columns} options={options} />
            </Box>
            <Stack direction={'row'} gap={1} sx={{ alignSelf: 'center' }}>
              <CustomIconButton
                disabled={page === 1}
                onClick={handlePreviousPage}
              >
                <ChevronLeftRounded />
              </CustomIconButton>
              <CustomIconButton
                disabled={page === Math.ceil(count / pageSize) || count === 0}
                onClick={handleNextPage}
              >
                <ChevronRightRounded />
              </CustomIconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CollabById;
